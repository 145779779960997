import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/common/Layout";
import { HeroBanner } from "../components/homepage/herobanner";

const PostTemplate = (props) => {
    const { frontmatter } = props.pageContext.node;

    return (
        <Layout
            title={frontmatter?.title}
            hrefLang="en"
            href={`https://www.unizo.ai${frontmatter?.slug ?? ""}`}
            description={frontmatter?.description}
            keywords={frontmatter?.keywords}
            canonical={frontmatter?.canonical}
        >
            {(frontmatter?.banner ?? true) && (
                <HeroBanner
                    title={frontmatter.title}
                    description={frontmatter?.description}
                    minimal
                />
            )}
            <MDXRenderer>{props?.pageContext?.node?.body}</MDXRenderer>
        </Layout>
    );
};

export default PostTemplate;
